import Player from "./Player";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Player />
    </div>
  );
}

export default App;
