export const data = [
  {
    name:"Dancing in My Room",
    artist:"347aidan",
    url:"https://storage.googleapis.com/fm1129/347aidan%20-%20Dancing%20in%20My%20Room.mp3"
  },
  {
    name:"呼吸决定",
    artist:"Fine乐团",
    url:"https://storage.googleapis.com/fm1129/Fine%E4%B9%90%E5%9B%A2%20-%20%E5%91%BC%E5%90%B8%E5%86%B3%E5%AE%9A.mp3"
  },
  {
    name:"第三人称",
    artist:"Hush!",
    url:"https://storage.googleapis.com/fm1129/Hush!%20-%20%E7%AC%AC%E4%B8%89%E4%BA%BA%E7%A7%B0.mp3"
  },
  {
    name:"Shallow",
    artist:"Lady Gaga,Bradley Cooper",
    url:"https://storage.googleapis.com/fm1129/Lady%20Gaga%2CBradley%20Cooper%20-%20Shallow.mp3"
  },
  {
    name:"MELANCHOLY",
    artist:"White Cherry",
    url:"https://storage.googleapis.com/fm1129/White%20Cherry%20-%20MELANCHOLY.mp3"
  },
  {
    name:"爱在夏天",
    artist:"告五人",
    url:"https://storage.googleapis.com/fm1129/%E5%91%8A%E4%BA%94%E4%BA%BA%20-%20%E7%88%B1%E5%9C%A8%E5%A4%8F%E5%A4%A9.mp3"
  },
  {
    name:"初恋",
    artist:"回春丹",
    url:"https://storage.googleapis.com/fm1129/%E5%9B%9E%E6%98%A5%E4%B8%B9%20-%20%E5%88%9D%E6%81%8B.mp3"
  },
  {
    name:"你要如何，我们就如何",
    artist:"康姆士COM'Z",
    url:"https://storage.googleapis.com/fm1129/%E5%BA%B7%E5%A7%86%E5%A3%ABCOM'Z%20-%20%E4%BD%A0%E8%A6%81%E5%A6%82%E4%BD%95%EF%BC%8C%E6%88%91%E4%BB%AC%E5%B0%B1%E5%A6%82%E4%BD%95.mp3"
  },
  {
    name:"无法坠入爱河 (Punk Version)",
    artist:"未来星B3Rich",
    url:"https://storage.googleapis.com/fm1129/%E6%9C%AA%E6%9D%A5%E6%98%9FB3Rich%20-%20%E6%97%A0%E6%B3%95%E5%9D%A0%E5%85%A5%E7%88%B1%E6%B2%B3%20(Punk%20Version).mp3"
  },
  {
    name:"漠河舞厅",
    artist:"柳爽",
    url:"https://storage.googleapis.com/fm1129/%E6%9F%B3%E7%88%BD%20-%20%E6%BC%A0%E6%B2%B3%E8%88%9E%E5%8E%85.mp3"
  },
  {
    name:"夏天的风",
    artist:"温岚",
    url:"https://storage.googleapis.com/fm1129/%E6%B8%A9%E5%B2%9A%20-%20%E5%A4%8F%E5%A4%A9%E7%9A%84%E9%A3%8E.mp3"
  },
  {
    name:"以爱为囚",
    artist:"满舒克",
    url:"https://storage.googleapis.com/fm1129/%E6%BB%A1%E8%88%92%E5%85%8B%20-%20%E4%BB%A5%E7%88%B1%E4%B8%BA%E5%9B%9A.mp3"
  },
  {
    name:"乘客",
    artist:"王菲",
    url:"https://storage.googleapis.com/fm1129/%E7%8E%8B%E8%8F%B2%20-%20%E4%B9%98%E5%AE%A2.mp3"
  },
  {
    name:"不期而遇的夏天",
    artist:"陈奕迅",
    url:"https://storage.googleapis.com/fm1129/%E9%99%88%E5%A5%95%E8%BF%85%20-%20%E4%B8%8D%E6%9C%9F%E8%80%8C%E9%81%87%E7%9A%84%E5%A4%8F%E5%A4%A9.mp3"
  },
  {
    name:"九份的咖啡店",
    artist:"陈绮贞",
    url:"https://storage.googleapis.com/fm1129/%E9%99%88%E7%BB%AE%E8%B4%9E%20-%20%E4%B9%9D%E4%BB%BD%E7%9A%84%E5%92%96%E5%95%A1%E5%BA%97.mp3"
  },
  {
    name:"小步舞曲",
    artist:"陈绮贞",
    url:"https://storage.googleapis.com/fm1129/%E9%99%88%E7%BB%AE%E8%B4%9E%20-%20%E5%B0%8F%E6%AD%A5%E8%88%9E%E6%9B%B2.mp3"
  },
  {
    name:"我喜欢上你时的内心活动",
    artist:"陈绮贞",
    url:"https://storage.googleapis.com/fm1129/%E9%99%88%E7%BB%AE%E8%B4%9E%20-%20%E6%88%91%E5%96%9C%E6%AC%A2%E4%B8%8A%E4%BD%A0%E6%97%B6%E7%9A%84%E5%86%85%E5%BF%83%E6%B4%BB%E5%8A%A8.mp3"
  },
  {
    name:"everything i wanted",
    artist:"Billie Eilish",
    url:"https://storage.googleapis.com/fm1129/Billie%20Eilish%20-%20everything%20i%20wanted.mp3"
  },
  {
    name:"Redbone",
    artist:"Childish Gambino",
    url:"https://storage.googleapis.com/fm1129/Childish%20Gambino%20-%20Redbone.mp3"
  },
  {
    name:"Lover Boy 88",
    artist:"Higher Brothers,Phum Viphurit",
    url:"https://storage.googleapis.com/fm1129/Higher%20Brothers%2CPhum%20Viphurit%20-%20Lover%20Boy%2088.mp3"
  },
  {
    name:"白日",
    artist:"King Gnu",
    url:"https://storage.googleapis.com/fm1129/King%20Gnu%20-%20%E7%99%BD%E6%97%A5.mp3"
  },
  {
    name:"不得不爱",
    artist:"Lambert",
    url:"https://storage.googleapis.com/fm1129/Lambert%20-%20%E4%B8%8D%E5%BE%97%E4%B8%8D%E7%88%B1.mp3"
  },
  {
    name:"想说",
    artist:"Lambert",
    url:"https://storage.googleapis.com/fm1129/Lambert%20-%20%E6%83%B3%E8%AF%B4.mp3"
  },
  {
    name:"Tonight",
    artist:"Lambert,Demxntia",
    url:"https://storage.googleapis.com/fm1129/Lambert%2CDemxntia%20-%20Tonight.mp3"
  },
  {
    name:"Homage",
    artist:"Mild High Club",
    url:"https://storage.googleapis.com/fm1129/Mild%20High%20Club%20-%20Homage.mp3"
  },
  {
    name:"stargazing",
    artist:"sapientdream",
    url:"https://storage.googleapis.com/fm1129/sapientdream%20-%20stargazing.mp3"
  },
  {
    name:"放空",
    artist:"大籽,Lil E,A1 TRIP",
    url:"https://storage.googleapis.com/fm1129/%E5%A4%A7%E7%B1%BD%2CLil%20E%2CA1%20TRIP%20-%20%E6%94%BE%E7%A9%BA.mp3"
  },
  {
    name:"形容",
    artist:"沈以诚",
    url:"https://storage.googleapis.com/fm1129/%E6%B2%88%E4%BB%A5%E8%AF%9A%20-%20%E5%BD%A2%E5%AE%B9.mp3"
  },
  {
    name:"我的名字",
    artist:"焦迈奇",
    url:"https://storage.googleapis.com/fm1129/%E7%84%A6%E8%BF%88%E5%A5%87%20-%20%E6%88%91%E7%9A%84%E5%90%8D%E5%AD%97.mp3"
  },
  {
    name:"Can't Take My Eyes Off You",
    artist:"王若琳",
    url:"https://storage.googleapis.com/fm1129/%E7%8E%8B%E8%8B%A5%E7%90%B3%20-%20Can't%20Take%20My%20Eyes%20Off%20You.mp3"
  },
  {
    name:"暧昧",
    artist:"王菲",
    url:"https://storage.googleapis.com/fm1129/%E7%8E%8B%E8%8F%B2%20-%20%E6%9A%A7%E6%98%A7.mp3"
  },
  {
    name:"大风吹",
    artist:"王赫野",
    url:"https://storage.googleapis.com/fm1129/%E7%8E%8B%E8%B5%AB%E9%87%8E%20-%20%E5%A4%A7%E9%A3%8E%E5%90%B9.mp3"
  },
  {
    name:"Lemon",
    artist:"米津玄師",
    url:"https://storage.googleapis.com/fm1129/%E7%B1%B3%E6%B4%A5%E7%8E%84%E5%B8%AB%20-%20Lemon.mp3"
  },
  {
    name:"光",
    artist:"陈粒",
    url:"https://storage.googleapis.com/fm1129/%E9%99%88%E7%B2%92%20-%20%E5%85%89.mp3"
  },
  {
    name:"奇妙能力歌",
    artist:"陈粒",
    url:"https://storage.googleapis.com/fm1129/%E9%99%88%E7%B2%92%20-%20%E5%A5%87%E5%A6%99%E8%83%BD%E5%8A%9B%E6%AD%8C.mp3"
  },
  {
    name:"小半",
    artist:"陈粒",
    url:"https://storage.googleapis.com/fm1129/%E9%99%88%E7%B2%92%20-%20%E5%B0%8F%E5%8D%8A.mp3"
  },
  {
    name:"虚拟",
    artist:"陈粒",
    url:"https://storage.googleapis.com/fm1129/%E9%99%88%E7%B2%92%20-%20%E8%99%9A%E6%8B%9F.mp3"
  },
  {
    name:"彼个所在",
    artist:"魏如萱",
    url:"https://storage.googleapis.com/fm1129/%E9%AD%8F%E5%A6%82%E8%90%B1%20-%20%E5%BD%BC%E4%B8%AA%E6%89%80%E5%9C%A8.mp3"
  },
  {
    name:"R&B All Night",
    artist:"KnowKnow,Higher Brothers",
    url:"https://storage.googleapis.com/fm1129/KnowKnow%2CHigher%20Brothers%20-%20R%26B%20All%20Night.mp3"
  },
  {
    name:"克卜勒",
    artist:"孙燕姿",
    url:"https://storage.googleapis.com/fm1129/%E5%AD%99%E7%87%95%E5%A7%BF%20-%20%E5%85%8B%E5%8D%9C%E5%8B%92.mp3"
  },
  {
    name:"达尔文",
    artist:"蔡健雅",
    url:"https://storage.googleapis.com/fm1129/%E8%94%A1%E5%81%A5%E9%9B%85%20-%20%E8%BE%BE%E5%B0%94%E6%96%87.mp3"
  },
  {
    name:"Dancin (Krono Extended Remix)",
    artist:"Aaron Smith",
    url:"https://storage.googleapis.com/fm1129/Aaron%20Smith%20-%20Dancin%20(Krono%20Extended%20Remix).mp3"
  },
  {
    name:"Love You Like A Love Song",
    artist:"Selena Gomez & The Scene",
    url:"https://storage.googleapis.com/fm1129/Selena%20Gomez%20%26%20The%20Scene%20-%20Love%20You%20Like%20A%20Love%20Song.mp3"
  },
  {
    name:"刻在我心底的名字",
    artist:"卢广仲",
    url:"https://storage.googleapis.com/fm1129/%E5%8D%A2%E5%B9%BF%E4%BB%B2%20-%20%E5%88%BB%E5%9C%A8%E6%88%91%E5%BF%83%E5%BA%95%E7%9A%84%E5%90%8D%E5%AD%97.mp3"
  },
  {
    name:"保留",
    artist:"郭顶",
    url:"https://storage.googleapis.com/fm1129/%E9%83%AD%E9%A1%B6%20-%20%E4%BF%9D%E7%95%99.mp3"
  },
  {
    name:"想着你",
    artist:"郭顶",
    url:"https://storage.googleapis.com/fm1129/%E9%83%AD%E9%A1%B6%20-%20%E6%83%B3%E7%9D%80%E4%BD%A0.mp3"
  },
  {
    name:"水星记",
    artist:"郭顶",
    url:"https://storage.googleapis.com/fm1129/%E9%83%AD%E9%A1%B6%20-%20%E6%B0%B4%E6%98%9F%E8%AE%B0.mp3"
  },
  {
    name:"我在人民广场吃炸鸡",
    artist:"阿肆",
    url:"https://storage.googleapis.com/fm1129/%E9%98%BF%E8%82%86%20-%20%E6%88%91%E5%9C%A8%E4%BA%BA%E6%B0%91%E5%B9%BF%E5%9C%BA%E5%90%83%E7%82%B8%E9%B8%A1.mp3"
  },
  {
    name:"热爱105°C的你",
    artist:"阿肆",
    url:"https://storage.googleapis.com/fm1129/%E9%98%BF%E8%82%86%20-%20%E7%83%AD%E7%88%B1105%C2%B0C%E7%9A%84%E4%BD%A0.mp3"
  },
];
